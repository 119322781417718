main{

}

header{
    text-align: center;
}

.cache-section{
    margin-bottom: 2rem;
}

.app-container{
    max-width: 768px;
    margin: 0 auto;
    padding: 1rem;
}

.cpcode-list{
    padding: 0;
    margin: 0;
    list-style-type: none;
    list-style-position: inside;
}

.cpcode {
    padding: 1rem;
    border: 2px solid royalblue;
    background: aliceblue;
    margin-bottom: 1rem;
    border-radius: 16px;
}

.cpcode h3{
    margin-top: 0;
}

.cpcode-cpcode{
    opacity: .75;
}

.clear-button{
    padding: 1rem;
}

.activity-log p.success{
    background: #b2ecb2;
}

.activity-log p.error{
    background: #ecb2b2;
}